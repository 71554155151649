import React from "react";

const Board = () => {
  return (
    <div>
      <h1>게시판</h1>
      <p>게시판 내용을 확인하세요.</p>
    </div>
  );
};

export default Board;